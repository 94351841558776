

.model-answer {
    background-color: #e9e9e9 ;
    transition: var(--main-transition);
    padding: 150px 0;

}

.model-answer.dark-mode {
    background-color: var(--color-Main-dark); ;
    transition: var(--main-transition);
}

.model-answer.dark-mode .answer-main ,
.model-answer.dark-mode .percentage-main , 
.model-answer.dark-mode  .answer-question  {
    box-shadow: -7px 15px 40px -5px #0006;
    background-color: var(--color-secondry-dark);;
        transition: var(--main-transition);
}

.model-answer.dark-mode .answer-main .box ,
.model-answer.dark-mode .percentage-main .box{
    background-color: var(--color-secondry-dark);;
        transition: var(--main-transition);
        color: #fff;
}

.model-answer .box {
    margin-bottom: 10px;
}
.model-answer .answers {
    display: flex;
    flex-wrap: wrap;
}

.model-answer .answer-main .box {
    background-color: #fff;
        padding: 10px;
        border-radius: 20px;
}

.model-answer .answer-main {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 20px 20px;
    position: relative;
    border-radius: 20px;
    }
    
    .model-answer .answer-main .answers {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 600;
        flex-direction: row-reverse;
    }
    .model-answer .answer-main .answers .box-answer{
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;    
        cursor: pointer;
        transition: var(--main-transition);
    }

    .model-answer .answer-main .answers .box-answer:hover {

        transform: scale(1.1);
    }
    

    .model-answer .answer-main .click-box {
        font-size: 1.5rem;
        text-align: right;
        font-weight: 600;
        margin: 10px;
    }

    .model-answer .answer-main .total-answer{
        text-align: right;
        padding: 15px;
        margin-top: 15px;
        
    }

    .model-answer .answer-main .total-answer .number {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    .model-answer .answer-main .total-answer p {
        font-size: 19px;
        font-weight: 600;
        /* margin-bottom: 40px; */
    }
    .model-answer .answer-main .total-answer  span {
        color:#fff;
      padding: 10px 15px;
        border-radius: 10px;
        margin-right: 0;
        margin-left: 0;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }

    .model-answer .answer-main .total-answer  span.correct {
        background-color: rgb(40, 167, 69);
    }

    .model-answer .answer-main .total-answer  span.incorrect {
        background-color: #dc3545 ;
        margin-right: 33px;
    }

.model-answer .answers .box-answer {
    padding: 12px 18px;
    /* height: 40px;
    width: 40px; */
    text-align: center;
    border-radius: 10px;
}

.model-answer .percentage-main{
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 20px 20px;
    position: relative;
    border-radius: 20px;
    height: 100%;
}

.model-answer .percentage-main .box {
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    background-color: #fff;
    padding: 10px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.model-answer .percentage-main .percentage {
   
    position: relative;
    border-radius: 50%;
    background-color: #e9e9e9;
}

.model-answer .percentage-main p {
    font-size: 18px;
    font-weight: 600;
}

.model-answer .percentage-main span {
    color: var(--color-Main-red);;
    font-size: 20px;
    margin-right: 10px;
    font-weight: 800;
    text-shadow: 0px 0px 40px #e02a24;
}

.model-answer .percentage-main .box .score{
text-align: center;
margin-top: 15px;
}

.model-answer  .CircularProgressbar .CircularProgressbar-path
{
    stroke: #3586ff;
    /* fill: black; */
  }

  .model-answer text.CircularProgressbar-text {
    fill:#000 ;
    font-weight: 600;
  }


.model-answer .choice {
    /* border: 1px solid #000; */
    padding: 10px 50px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}

.model-answer .correct ,
.model-answer .incorrect {
    margin-right: 50px;
    background-color: #fff;
    border-radius: 10px;
    font-size: 18px;
    padding: 5px 10px;
    font-weight: 600;
}


.model-answer .incorrect {
    color: rgb(168, 6, 0);
    border:  6px solid rgba(168  6 0 / 0.5);
}
.model-answer .correct {
    color: rgb(40, 167, 69);
    border:  6px solid rgba(17 94 89 / 0.5);
}
/* 
.model-answer .box {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 10px 10px;
    position: relative;
} */

.model-answer .answer-question .box-answer {
    padding: 10px;
    color: white;
    font-size: 22px;
    font-weight: 600;
    direction: ltr;
}

.model-answer .answer-question {
    box-shadow: inset 5px 5px 5px rgba(0,0,0,0.05),
    inset -5px -5px 5px rgba(255 , 255 , 255 , 0.5),
    -5px -5px 5px rgba(0 , 0 , 0 , 0.05),
    -5px -5px 5px rgba(255 , 255 , 255 , 0.5);
    padding: 10px 20px;
    position: relative;
    border-radius: 20px;
    margin-top: 30px;
}

.model-answer .answer-question .box {
    border-radius: 20px;
    /* text-align: left ; */
    padding: 0 20px;
    direction: rtl;
}

.model-answer .answer-question .question {
    font-size: 35px;
    font-weight: 600;
    text-align: right;
}
.model-answer .answer-question .question-box {
        flex-direction: column;
        direction: ltr;
    text-align: left;
}

.model-answer .answer-question img {
    width: 100%;
    /* margin-left: 63px; */
    margin-bottom: 30px;
    border-radius: 20px
}

@media (max-width:767px) {
    .model-answer .answer-question img {
        width:90%;
        margin-left: 0;
    }
    .model-answer .answer-question .question-box img {
        width: 100%;
        border-radius: 0;
        min-height: 100%;
        
    }
    .model-answer .choice {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        padding: 10px 10px;
    }
    .model-answer .answer-main {
        margin-top: 30px;
    }
}
.model-answer  .counter {
    color: #fff;
    font-size: 40px;
    margin-left: 20px;
}

.model-answer .non {
    text-align: left;
}

@media (max-width: 767px) {
    .model-answer .correct,
     .model-answer .incorrect  {
        margin-right: 0;
     }
}


.button-86 {
    all: unset;
    /* width: 100px;
    height: 30px; */
    font-size: 1.4rem;
    font-weight: 600;
    background: transparent;
    border: none;
    position: relative;
    color: #f0f0f0;
    /* cursor: pointer; */
    z-index: 1;
    padding: 15px 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    margin: 0 auto;
  }
  
  .button-86::after,
  .button-86::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -99999;
    transition: all .4s;
  }
  
  .button-86::before {
    transform: translate(0%, 0%);
    width: 100%;
    height: 100%;
    background: #28282d;
    border-radius: 10px;
  }
  
  .button-86::after {
    transform: translate(10px, 10px);
    width: 35px;
    height: 35px;
    background: #ffffff15;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50px;
  }
  
  .button-86:hover::before {
    transform: translate(5%, 20%);
    width: 110%;
    height: 110%;
  }
  
  .button-86:hover::after {
    border-radius: 10px;
    transform: translate(0, 0);
    width: 100%;
    height: 100%;
  }
  
  .button-86:active::after {
    transition: 0s;
    transform: translate(0, 5%);
  }  

  

.model-answer .explain p {
    font-size: 1.4rem;
}

.model-answer .explain h3 {
    font-size: 2.3rem;
    font-weight: 600;
}