/* input.search-table.form-control {
    width: 25%;
    margin-bottom:20px;
}
#table-to-xls {
    display: none;
}

.sc-dkrFOg.iSAVrt.rdt_TableHeadRow  {
    padding: 10px 10px;
    border-top: 3px solid #e03a56;
    border-bottom: 3px solid #e03a56;
    font-size: 1rem;
    font-weight: 600;
    margin-top: -11px;
}

.sc-hHTYSt.dOIzqm.rdt_TableBody {
    padding: 10px;
    font-size: 2rem;
}
.sc-dmctIk.WQNxq {
    border-radius: 0;
}


.table-code.cxcOiE {
padding: 5px;
}
.table-search {
    width: 25%;
}
header.sc-dIfARi.kDEcCH {
    border-radius: 20px 20px 0 0;

} */

input#inputcharge {
    width: 100%;
}

.total-student {
    /* display: flex;
    align-items: center;
    flex-direction: column-reverse;
    justify-content: space-between; */
    font-size: 1.3rem;
    margin-bottom: 0;
    font-weight: 600;
}
.total-student span {
    color: red;
    /* font-weight: ; */
}
.total-search {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
}

.chargeform {
    flex-direction: column;
}

.total-search h2 {
    font-size: 2rem;
}
.charge {
    width: 100%;
    margin-bottom: 15px;
    margin-left: 25px;

}

@media (max-width:767px) {
    input.search-table.form-control {
        width: 100%;
    }
    
button.btn {    
    padding: 10px 7px;
}
}

@media (min-width:767px) and (max-width:1023px) {
    .create-code .quiz-content .codes form {
        flex-direction: column;
} 
/* button.btn {    
    padding: 10px 7px;
}
input.search-table.form-control {
    width: 100%;
} */
}
